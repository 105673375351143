import React from "react"
import { Link } from "gatsby"

const Header = ({onClickHandler}) =>{
    return (
        <header className="l-header">
            <div className="p-header-site-name">
                <Link to="/">
                    <span>めざせ!歯科衛生士</span>
                    <span>歯科衛生士になるための情報マガジン</span>
                </Link>
            </div>

            <div className="p-header-nav">
                <input type="checkbox" id="c-header-toggle" onClick={onClickHandler} />
                <label for="c-header-toggle">
                    <span></span>
                    <span>Menu</span>
                    <span>Close</span>
                </label>

                <nav>
                    <div class="p-header-site-name">
                        <Link to="/">
                            <span>めざせ!歯科衛生士</span>
                            <span>歯科衛生士になるための情報マガジン</span>
                        </Link>
                    </div>
                    <div className="c-global-nav-1st">
                        <ul>
                            <li><Link to="/naruniha">歯科衛生士に<span>なるには</span></Link></li>
                            <li><Link to="/shigoto">歯科衛生士の<span>仕事内容</span></Link></li>
                        </ul>
                        <ul>
                            <li>
                                <span>歯科衛生士の魅力</span>
                                <ul>
                                    <li><Link to="/salary">大卒より<em>高い初任給</em></Link></li>
                                    <li><Link to="/shikaku">一生使える<em>国家資格</em></Link></li>
                                    <li><Link to="/employment">就職率はなんと<em>9割以上</em></Link></li>
                                    <li><Link to="/hobby">趣味や遊びも<em>両立</em></Link></li>
                                    <li><Link to="/medical">患者さんを<em>笑顔にする医療職</em></Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div className="c-global-nav-2nd">
                        <ul>
                            <li><Link to="/voice">学生VOICE</Link></li>
                            <li><Link to="/active">歯科衛生士の活躍</Link></li>
                            <li><Link to="/future">歯科衛生士の将来</Link></li>
                            <li><Link to="/qa">歯科衛生士Q&amp;A</Link></li>
                            <li><Link to="/school">全国の歯科衛生士学校</Link></li>
                        </ul>
                        <ul>
                            <li><Link to="/news">お知らせ</Link></li>
                            <li><Link to="/about">めざせ！歯科衛生士について</Link></li>
                            <li><a href="https://www.guppy.co.jp/contact/" target="_blank">コンタクト</a></li>
                            <li><Link to="/terms" >利用規約</Link></li>
                            <li><Link to="/policy">プライバシーポリシー</Link></li>
                            <li><Link to="/sitemap">サイトマップ</Link></li>
                        </ul>
                    </div>

                </nav>
            </div>

        </header>
    )
}

export default Header